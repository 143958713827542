export default {
  'Shared.Finish': '完成',
  'Shared.RotateDeviceContent':
    '为确保功能正常，且让您有最好的使用体验，请将您的萤幕旋转为横向，谢谢！',
  'Shared.Version': '版本',
  'Shared.Install': '安装',
  'Shared.PromptMsg': '取得我们的免费 APP, 它不会耗费太多你的手机空间',
  'Shared.SafariHint0': '使用 Safari 開啟頁面',
  'Shared.SafariHint1': '点击',
  'Shared.SafariHint2': '选择 添加到主屏幕',
  'Shared.A2HS': '添加到主屏幕',
  'Shared.Introduction': '教学',
  'Message.EnterAnyPress': '点击画面开始',
  'common.system.maintence': '系统维护中',
  'common.system.maintainTime': '维护时间',
};
