import en from './en';
import th from './th';
import my from './my';
import vi from './vi';
import cn from './zh-Hans';
import tw from './zh-Hant';

export default {
  en,
  th,
  my,
  vi,
  'zh-Hans': cn,
  'zh-Hant': tw,
};
