// import Injector from 'vue-inject';
import set from 'lodash/set';

class FieldConfigMapping {
  constructor() {
    let self = this;
  }

  mapping(config, workingTypes = []) {
    let mappingConfig = {};
    Object.entries(config).forEach(([type, typeConfig]) => {
      if (!workingTypes.includes(type)) {
        return;
      }
      Object.entries(typeConfig).forEach(([kind, kindConfig]) => {
        Object.entries(kindConfig).forEach(([fieldName, fieldConfig]) => {
          let configManipulate = fieldConfig.manipulate;
          let viewsConfig = configManipulate.views;
          let commonConfig = {
            length: fieldConfig.length,
            pattern: fieldConfig.pattern === undefined ? null : new RegExp(fieldConfig.pattern),
            manipulate: {
              unique: configManipulate.unique == 1,
              memberLoginable: configManipulate.memberLoginable == 1,
            },
          };

          Object.entries(viewsConfig).forEach(([viewName, viewConfig]) => {
            if (viewName.indexOf('mem') > -1) {
              set(mappingConfig, `${viewName}.${kind}.${fieldName}`,
                {
                  ...commonConfig,
                  // OCMS-790 客制field.manual editable跟dupCheck 參數
                  fieldConfig: {
                    visible: viewConfig.visible == 1,
                    require: viewConfig.require == 1,
                    editable: type == 'manual' ? viewConfig.visible : viewConfig.editable, // 編輯 0: 唯讀, 1: 可以編輯, 2: 空白時可以編輯(相等於只能編輯一次)
                    dupCheck: type == 'manual' ? false : viewConfig.dupCheck == 1,
                    verification: viewConfig.verification,
                  },
                });
            }
          });
        });
      });
    });

    return mappingConfig;
  }
}

const FieldConfigMappingInstance = new FieldConfigMapping();

// Injector.factory('FieldMapping', () => FieldConfigMappingInstance);

export default FieldConfigMappingInstance;
