export default {
  'Shared.Finish': '完成',
  'Shared.RotateDeviceContent':
    '為確保功能正常，且讓您有最好的使用體驗，請將您的螢幕旋轉為橫向，謝謝！',
  'Shared.Version': '版本',
  'Shared.Install': '安裝',
  'Shared.PromptMsg': '取得我們的免費 APP, 它不會耗費太多你的手機空間',
  'Shared.SafariHint0': '使用 Safari 開啟頁面',
  'Shared.SafariHint1': '點擊',
  'Shared.SafariHint2': '選擇 添加到主畫面',
  'Shared.A2HS': '添加到主畫面',
  'Shared.Introduction': '教學',
  'Message.EnterAnyPress': '點擊畫面開始',
  'common.system.maintence': '系統維護中',
  'common.system.maintainTime': '維護時間',
};
