export default {
  inject: [],
  name: '',
  components: {},
  props: {
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 20,
    },
    showTotalCount: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {
  },
  mounted() {
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit('update:limit', val);
      },
    },
    totalCount: {
      get() {
        return this.total;
      },
      set(val) {
        // console.log(val)
        this.$emit('update:total', val);
      },
    },
    totalPage: {
      get() {
        return Math.ceil(this.total / this.limit);
      },
    },
    isPrevDisabled() {
      return this.currentPage === 1;
    },
    isNextDisabled() {
      return this.currentPage >= this.totalPage;
    },
  },
  methods: {
    prevPage(val) {
      const newVal = Number(val) - 1;
      if (Number(newVal) <= 0) {
        this.$emit('pagination', { page: 1, limit: this.pageSize });
      } else {
        this.$emit('pagination', { page: newVal, limit: this.pageSize });
      }
    },
    nextPage(val) {
      const newVal = Number(val) + 1;
      if (this.totalPage === 0) {
        this.$emit('pagination', { page: 1, limit: this.pageSize });
      } else if (Number(newVal) >= this.totalPage) {
        this.$emit('pagination', { page: this.totalPage, limit: this.pageSize });
      } else {
        this.$emit('pagination', { page: newVal, limit: this.pageSize });
      }
    },
  },
};
