export default {
  'Shared.Finish': 'ပြီးဆုံးရန်',
  'Shared.RotateDeviceContent':
    'အကောင်းဆုံးကစားနိုင်ရန်ဖုန်းကိုလှည့်ပြီးကစားပါ',
  'Shared.Version': 'ဗားရှင်း',
  'Shared.Install': 'အင်စတော',
  'Shared.PromptMsg':
    'ကျွန်ုပ်တို့၏ အခမဲ့Appကို ရယူလိုက်ပါ။ ၎င်းသည် သင့်ဖုန်းတွင် နေရာအများမကုန်ပါ',
  'Shared.SafariHint0': 'Safari မှဖွင့်ပါ',
  'Shared.SafariHint1': 'Tap ကိုနှိပ်ပါ',
  'Shared.SafariHint2': 'ပင်မစာမျက်နှာသို့ထည့်ရန် ရွေးပါ',
  'Shared.A2HS': 'Add to Home Screen',
  'Shared.Introduction': 'လုပ်ဆောင်နည်း',
  'Message.EnterAnyPress': 'Any Key ကိုနှိပ်ပါ။',
  'common.system.maintence': 'နည်းစနစ်အားပြုပြင်မွမ်းမံခြင်း၊၊',
  'common.system.maintainTime': 'ပြုပြင်ထိန်းသိမ်းချိန်',
};
