import { mapActions } from 'vuex';
import financialConstants from '~/utils/constant/financial';

export default {
  methods: {
    ...mapActions('financial', ['actionApplePayBilling']),
    checkAppleServiceSupported() {
      if (window.webkit
            && window.webkit.messageHandlers
            && window.webkit.messageHandlers['iap-products-request']
            && window.webkit.messageHandlers['iap-purchase-request']
      ) {
        return true;
      }
      return false;
    },
    checkPurchaseApple(ids) {
      if (!this.checkAppleServiceSupported) {
        throw new Error('Unsupport apple pay');
      }

      const maskIds = ids.map((id) => `ios_${id}`);

      window?.webkit?.messageHandlers['iap-products-request']?.postMessage(maskIds);
    },
    async makePurchaseApple(formData) {
      if (!this.checkAppleServiceSupported) {
        throw new Error('Unsupport apple pay');
      }

      try {
        this.$store.commit('app/SET_ISLOADING', true);
        window.webkit.messageHandlers['iap-purchase-request']
          .postMessage(JSON.stringify({ productID: `ios_${formData.paymentShopId}`, quantity: 1 }));
      } catch (error) {
        throw new Error(`call iap-purchase-request event fail, error: ${error}`);
      }
    },
    async verifyPurchaseApple(token) {
      // 驗證取得的付款資訊
      const paymentResult = await this.actionApplePayBilling({ token })
        .catch(async (err) => {
          console.error(err);
          this.$store.commit('app/SET_ISLOADING', false);
          return false;
        });
      this.$store.commit('app/SET_ISLOADING', false);

      if (paymentResult?.isSuccess) {
        // 延遲 500ms 讓單一錢包轉帳
        setTimeout(() => this.$store.dispatch('financial/actionQueryAllPlatMoney'), 500);
        this.$store.dispatch('mailBox/actionGetAllMailBox');
      }
    },
    handlePurchaseResult(status) {
      switch (status) {
        case financialConstants.appleStore.purchaseResult.CANCELED:
          this.$store.commit('app/SET_ISLOADING', false);
          break;
        case financialConstants.appleStore.purchaseResult.FAILED:
        case financialConstants.appleStore.purchaseResult.NOT_FOUND:
          this.$store.commit('app/SET_ISLOADING', false);
          console.error('purchase fail reason: ', status);
          break;

        case financialConstants.appleStore.purchaseResult.SUCCESS:
        default:
          break;
      }
    },
    initApplePayEvent() {
      // apple pay 回應
      window.addEventListener('iap-purchase-transaction', (event) => {
        if (event && event.detail) {
          // console.log('iap-purchase-transaction :', event.detail);
          this.verifyPurchaseApple(event.detail);
        }
      });
      // 確認商品回應
      // window.addEventListener('iap-products-result', (event) => {
      //   if (event && event.detail) {
      //     console.debug('iap-products-result :', JSON.parse(event.detail));
      //   }
      // });
      // 購買結果
      window.addEventListener('iap-purchase-result', (event) => {
        if (event && event.detail) {
          // console.log('iap-purchase-result :', event.detail);
          this.handlePurchaseResult(event.detail);
        }
      });
    },
  },
};
