export default {
  'Shared.Finish': 'Finish',
  'Shared.RotateDeviceContent':
    'Please Rotate Your Device For The Best Experience',
  'Shared.Version': 'Version',
  'Shared.Install': 'Install',
  'Shared.PromptMsg': "Get our free app. It won't take up space on your phone",
  'Shared.SafariHint0': 'Open in Safari',
  'Shared.SafariHint1': 'Tab on',
  'Shared.SafariHint2': 'Select Add to Home Screen',
  'Shared.A2HS': 'Add to Home Screen',
  'Shared.Introduction': 'Introduction',
  'Message.EnterAnyPress': 'Tap to Start',
  'common.system.maintence': 'System Maintenance',
  'common.system.maintainTime': 'Maintenance time',
};
